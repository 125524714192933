import React from 'react'

const Checkout = () => {
  return (
    <div>
      Checkout
    </div>
  )
}

export default Checkout
